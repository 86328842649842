import React, { Fragment } from "react";
import { Typography } from "@mui/material";

function Layout({ children }) {
    return (
      <Fragment>
        <Typography component="div">            
          {children}              
        </Typography>
      </Fragment>
    );
  }

export default Layout;
