import React, { useState, useEffect, Fragment } from "react";
import Layout from "../components/Layout";
import { useRouter } from "next/router";
import ErrorBoundary from "../components/ErrorBoundary";
import "../styles/globals.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from '@/src/theme';
import PageLoader from "@/components/loader";


export default function MyApp(props) {
  const { Component, pageProps } = props;
  const Router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Router.events.on("routeChangeStart", () => checkRouter());
    Router.events.on("routeChangeComplete", () => setLoading(false));
    Router.events.on("routeChangeError", () => setLoading(false));
    return () => {
      Router.events.off("routeChangeStart", () => checkRouter());
      Router.events.off("routeChangeComplete", () => setLoading(false));
      Router.events.off("routeChangeError", () => setLoading(false));
    };
  }, [Router.events]);

  const checkRouter = () => {
    setLoading(true)
  }

  return (
    <Fragment>
      {loading ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PageLoader />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <ErrorBoundary>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </ErrorBoundary>
        </ThemeProvider>
      )}
    </Fragment>
  );
}
